import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import chartChallenged from 'stile-shared/assets/images/evidence-of-success/chart_challenged.svg';
import chartEngagement from 'stile-shared/assets/images/evidence-of-success/chart_engagement.svg';
import chartRealworld from 'stile-shared/assets/images/evidence-of-success/chart_realworld.svg';
import chartSavesTime from 'stile-shared/assets/images/evidence-of-success/chart_saves_time.svg';
import chartScientificLiteracy from 'stile-shared/assets/images/evidence-of-success/chart_scientific_literacy.svg';
import symbolTierFour from 'stile-shared/assets/images/evidence-of-success/tier_4.svg';
import westEdLaurel from 'stile-shared/assets/images/home/west_ed_laurel.svg';

import { MediaBlock } from 'stile-shared/src/components/2020/blocks/MediaBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { TwinklingStarsBlock } from 'stile-shared/src/components/2020/blocks/TwinklingStarsBlock';
import { ButtonLink } from 'stile-shared/src/components/2020/common/ButtonLink';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import {
  H2,
  H3,
  P,
  PageHeading,
  Spacer,
  Text,
} from 'stile-shared/src/components/2020/common/Primitives';
import { Center } from 'stile-shared/src/components/2020/layout/Center';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { Row } from 'stile-shared/src/components/2020/layout/Row';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function EvidenceOfSuccessPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <TwinklingStarsBlock>
        <img style={{ width: '150px' }} src={westEdLaurel} alt="" />
        <PageHeading>Built on evidence-based science</PageHeading>
        <P>
          Developed by applying rigorous research, the Stile Curriculum adheres to a meticulously
          defined logic model.
        </P>
        <ButtonLink openInNewTab url="/resources/johns-hopkins-report/">
          Download the report
        </ButtonLink>
      </TwinklingStarsBlock>

      <TextBlock variant="beige">
        <Row>
          <TierFourInfo
            title="Logic Model"
            text="Well-defined logic model based on rigorous research"
          />
          <TierFourInfo
            title="Research Intervention"
            text="Planned effort to study the effects of an intervention"
          />
        </Row>
        <P>
          Senior researchers from Johns Hopkins University’s Center for Research and Reform in
          Education independently evaluated Stile. They found the curriculum underpinned by rigorous
          research and a carefully articulated logic model.
        </P>
      </TextBlock>

      <TextBlock title="Global Insights from the Science Education Report">
        <P>
          In late 2022, over 1,100 high school science teachers from the US, Australia and New
          Zealand took part in the annual Science Education Report. While this report is part of our
          steadfast commitment to continuous improvement – we use the report’s findings to refine
          our learning resources and other materials – it also revealed some exciting insights on
          Stile’s positive impact in the science classroom.
        </P>
      </TextBlock>

      <TextBlock space={theme.space.m}>
        <H2>This is the impact teachers are seeing in their classrooms</H2>

        <Column space={theme.space.xl} role="list">
          <Column space={theme.space.m} role="listitem">
            <Text as="h3" bold>
              Stile helps me more effectively engage my students in the science classroom
            </Text>

            <img
              style={{ width: '350px' }}
              src={chartEngagement}
              alt="A horizontal bar chart showing 30% of teachers responded 'Strongly agree' and 45% responded 'Agree'"
            />

            <Text>
              The reason we started Stile is to make science interesting, accessible, yet
              challenging for every student. It is reassuring that this is reflected in the results
              as over 75% of teachers agreed or strongly agreed.
            </Text>
          </Column>

          <Column space={theme.space.m} role="listitem">
            <Text as="h3" bold>
              My students are being appropriately challenged by Stile’s lessons
            </Text>

            <img
              style={{ width: '350px' }}
              src={chartChallenged}
              alt="A horizontal bar chart showing 31% of teachers responded 'Strongly agree' and 45% responded 'Agree'"
            />

            <Text>
              Over 75% of teachers agreed to strongly agreed that their students are appropriately
              challenged by Stile. This includes ensuring there are challenging opportunities for
              more able students while building self-efficacy in every student so they feel
              confident in their ability to continually learn and adapt through a multitude of
              careers.
            </Text>
          </Column>

          <Column space={theme.space.m} role="listitem">
            <Text as="h3" bold>
              More discussion around real-world problems
            </Text>

            <img
              style={{ width: '350px' }}
              src={chartRealworld}
              alt="A horizontal bar chart showing 11% of teachers responded 'Strongly agree' and 42% responded 'Agree'"
            />

            <Text>
              Of teachers that responded, over 50% agreed to strongly agreed that students at their
              school are becoming more interested in social issues that have a scientific element.
              Stile lessons are designed to contextualize learning with real-world, socio-scientific
              issues that will engage students in their learning.
            </Text>
          </Column>

          <Column space={theme.space.m} role="listitem">
            <Text as="h3" bold>
              I believe that Stile has played an important role in improving my students’ scientific
              literacy
            </Text>

            <img
              style={{ width: '350px' }}
              src={chartScientificLiteracy}
              alt="A horizontal bar chart showing 21% of teachers responded 'Strongly agee' and 45% responded 'Agree'"
            />

            <Text>
              When asked if Stile has played an important role in improving their students’
              scientific literacy, two thirds of teachers agreed to strongly agreed. Scientific
              literacy is defined as the ability to engage with science-related issues, and with the
              ideas of science, as a reflective citizen.
            </Text>
          </Column>

          <Column space={theme.space.m} role="listitem">
            <Text as="h3" bold>
              More time given back to teachers to teach
            </Text>

            <img
              style={{ width: '350px' }}
              src={chartSavesTime}
              alt="A horizontal bar chart showing 57% of teachers responded 'Strongly agree' and 32% responded 'Agree'"
            />

            <Text>
              We asked teachers if Stile saves them time and 89% of teachers agreed to strongly
              agreed. That means Stile teachers have more time for the things that matter most:
              getting to know their students, providing effective feedback and challenging them at
              their level.
            </Text>
          </Column>
        </Column>

        <Spacer />

        <Column align="center" style={{ maxWidth: '50%', margin: 'auto' }}>
          <StaticImage
            src="../../../../stile-shared/assets/images/evidence-of-success/scied_book_cover.png"
            alt=""
          />
        </Column>
        <P>
          Have a read of the{' '}
          <Link url="https://stileapp.com/go/scied23">Science Education Report 2023</Link>.
        </P>
      </TextBlock>

      <MediaBlock layout="montage-two">
        <StaticImage
          src="../../../../stile-shared/assets/images/evidence-of-success/evidence_of_success_01.png"
          alt="A classroom of students, all focused on someone offscreen"
        />
        <StaticImage
          src="../../../../stile-shared/assets/images/evidence-of-success/evidence_of_success_02.png"
          alt="A teacher holding a tablet explains something to a student"
        />
      </MediaBlock>

      <NextPageBlock path="/why-choose-stile/evidence-based-pedagogy/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default EvidenceOfSuccessPage;

function TierFourInfo({ title, text }: { title: string; text: string }) {
  return (
    <Center max="300px" textAlign="center">
      <Column align="center">
        <img src={symbolTierFour} alt="" width="50%" />
        <H3>{title}</H3>
        <P size="s">{text}</P>
      </Column>
    </Center>
  );
}
